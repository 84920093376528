// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b3_k3";
export var caseStudyBackground = "b3_nX";
export var caseStudyBackground__lineColor = "b3_k0";
export var caseStudyCta__bgLight = "b3_nY";
export var caseStudyHead = "b3_kW";
export var caseStudyHead__imageWrapper = "b3_kX";
export var caseStudyProjectsSection = "b3_k4";
export var caseStudyQuote__bgRing = "b3_kZ";
export var caseStudySolution__ring = "b3_k8";
export var caseStudySolution__ringBottom = "b3_nZ";
export var caseStudySolution__ringRight = "b3_n0";
export var caseStudyTechnology__bg = "b3_n1";
export var caseStudy__bgDark = "b3_kV";
export var caseStudy__bgLight = "b3_kT";