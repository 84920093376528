import { Link } from 'gatsby'
import { StaticImage, getImage } from 'gatsby-plugin-image'

import solutionRingBottom from '~/assets/images/case-study/ifnd/pattern-bottom.svg'
import solutionRingRight from '~/assets/images/case-study/ifnd/pattern-right.svg'
import solutionRing from '~/assets/images/case-study/ifnd/pattern.svg'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'

import * as containerStyles from './IFnd.module.scss'
import useIFndStaticQuery from './useIFndStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

export const quotes = {
  be: {
    name: 'Maksym',
    position: 'Tech Lead & Rails expert at Codica',
  },
  pm: {
    name: 'Arpi',
    position: 'Project Manager at Codica',
  },
}

const previewTraderVsTitle = 'Recreational Vehicles Marketplace Platform'
const previewDetroitTitle = 'Online firearms sales platform'

const CaseStudiesIFnd = () => {
  const query = useIFndStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudyHead}>
        <CaseStudyHead
          title="Auto Parts Marketplace for ifndautoparts"
          subtitle="Learn how Codica provided a full development cycle to deliver an intuitive online marketplace to transform the way people buy and sell auto parts by making the process easier, faster, and smarter."
          maxWidthInfo="375px"
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAlt="Auto Parts Marketplace for ifndautoparts"
          imageTitle="Auto Parts Marketplace for ifndautoparts"
          domain="Automotive Parts"
          location="The United Kingdom"
          timeline="May 2022 - Present"
          services={
            <>
              <Link to="/services/product-discovery/" key="productDiscoveryKey">
                Product discovery
              </Link>
              ,
              <Link
                to="/services/ui-ux-design/"
                className="ml5"
                key="designKey"
              >
                UX/UI design services
              </Link>
              ,
              <Link
                to="/services/online-marketplace-development/"
                className="ml5"
                key="marketplaceDevKey"
              >
                Online marketplace development services
              </Link>
            </>
          }
          team="2 Frontend Developers, 2 Backend Developers, 2 QA Engineers, 1 DevOps, 1 UX/UI Designer, 2 Tech Leads, 1 Project Manager"
          technologies="NextJS, React, Expo, React Native, Ruby, Rails, Redis, Sidekiq"
        />
      </div>

      <div className={containerStyles.caseStudyBackground}>
        <CaseStudyBackground
          backgroundColor={containerStyles.caseStudy__bgDark}
          lineColor={containerStyles.caseStudyBackground__lineColor}
          bgTitleLeftList="Project challenges"
          bgTextLeft="Our client, ifndautoparts strived to have a platform that unites car enthusiasts and provides fast and efficient ways for them to buy and sell auto parts. The platform transforms how car enthusiasts buy and sell auto parts online by uniting them into a community, optimizing the process, simplifying it, and avoiding redundant ecommerce practices."
          bgTextRight="For the duration of our cooperation, the client requested a variety of features, all focused on bringing valuable quality-of-life improvements to the auto parts trade. They include multiple payment and authorization options, a robust search system, address verification and geolocation services, shipping automation, and more."
          sectionBusiness={[
            {
              label:
                'Develop and release a full-fledged, feature-rich marketplace without the MVP stage, skipping early feedback gathering and iteration.',
            },
            {
              label:
                'Design and build separate apps for iOS and Android platforms to elevate the experience for mobile users, making it seamless and fast.',
            },
            {
              label:
                'Implement multiple payment systems at once and make them equally accessible, user-friendly, and interchangeable, adding a layer of complexity to the checkout process from the development standpoint.',
            },
            {
              label:
                'Create a cohesive, user-friendly, and thoughtful UI/UX design system considering the cross-platform nature of the marketplace.',
            },
            {
              label:
                'Integrate and regularly refine a plethora of features based on iterative feedback during the collaboration between the client and the Codica team.',
            },
          ]}
          sectionValueDelivered={[
            {
              label:
                'Developed and launched a feature-rich marketplace without an MVP stage, thanks to rigorous testing and despite the lack of early feedback cycles.',
            },
            {
              label:
                'Integrated multiple seamless payment systems, ensuring a smooth, user-friendly, and interchangeable checkout experience while overcoming technical complexities.',
            },
            {
              label:
                'Created comprehensive design documentation outlining UI/UX design solutions and their implementation for each particular platform.',
            },
            {
              label:
                'Implemented a full set of features within deadlines thanks to iterative collaboration and an Agile development approach.',
            },
          ]}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudyCta__bgLight}
        title="Do you want to create a profitable and successful online marketplace?"
        description="Our experts employ the best industry approaches to realize your business idea."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Smart search system"
          description="Our fascination with robust modern search systems led us to implement Elasticsearch. Simply, it is an ideal choice for marketplaces as it provides fast and scalable full-text search and supports complex queries and filtering. In ifndautoparts it does precisely that-powers product search, filtering, and auto-suggestions."
          alt="Search system and filters example implemented by Codica for ifndautoparts marketplace"
          imageTitle="Example of a customizable smart search system implemented by Codica"
          bgRings
          bgRingImage={solutionRingBottom}
          bgRingClass={containerStyles.caseStudySolution__ringBottom}
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Advanced storefront"
          description={[
            'For buyers, storefronts bring business to a whole new level. Each storefront features a product display with images, prices, and descriptions, supported by an intuitive filter and sorting system that helps buyers search by category, rating, and price.',
            <div className="mb-2" key="descr" />,
            'Another honorable mention goes to the customization options we developed to create a branded shopping experience that enhances credibility and customer engagement. This feature elevates sellers’ product presentations and also makes sure buyers enjoy a seamless browsing experience.',
          ]}
          alt="Advanced storefront functionality developed for ifndautoparts marketplace"
          imageTitle="Example of a customizable storefront implemented by Codica team"
          bgRings
          bgRingImage={solutionRingRight}
          bgRingClass={containerStyles.caseStudySolution__ringRight}
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Multiple payment options"
          description="We know how important fast payment in marketplaces is. Often, it is a crucial factor that dictates whether users stick with the platform or leave in search of alternatives. Recognizing this importance, we implemented a pair of payment gateways, Stripe and PayPal. In combination, they provide flexibility and freedom of choice, allowing users to pay with credit or debit cards and Google or Apple Pay."
          alt="Advanced payment functionality developed for the ifndautoparts marketplace"
          imageTitle="An example of multiple payment solutions implemented by the Codica team"
          bgRings
          bgRingImage={solutionRing}
          bgRingClass={containerStyles.caseStudySolution__ring}
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Inventory management for business"
          description={[
            'Managing a vast inventory is never easy without proper tools. Recognizing that, we developed inventory management tools specifically for business sellers.',
            <div className="mb-2" key="descr" />,
            'A centralized inventory table is a nexus where sellers view, edit, filter, and manage their stock efficiently. Automated stock control ensures real-time inventory updates, reducing the risk of overselling. Sellers can also export inventory data, duplicate listings for faster product creation, and generate and scan inventory labels for seamless tracking.',
            <div className="mb-2" key="descrTwo" />,
            'Needless to say how this functionality simplifies stock management, minimizes errors, enhances day-to-day operations and grants better control over inventory on the platform.',
          ]}
          alt="An advanced inventory management system developed for ifndautoparts marketplace"
          imageTitle="Demonstration of inventory management functionality implemented by Codica team"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Auctions and offers"
          description={[
            'To improve pricing flexibility and engagement, we developed a dynamic Offers and Auctions system.',
            <div className="mb-2" key="descr" />,
            'With Offers, sellers can enable negotiations, set a minimum price, and respond to buyer proposals within a set timeframe. Buyers submit offers, which can be accepted, declined, or countered, ensuring seamless transactions. Accepted offers automatically reserve the item for checkout, while below-minimum offers are instantly rejected to prevent lowballing.',
            <div className="mb-2" key="descrTwo" />,
            'The Auction feature enables competitive bidding, with real-time price updates and a transparent reserve system. Buyers can track bids, receive notifications, and view bid history, while sellers can adjust pricing, lower the reserve price mid-auction, or re-list items if unsold. If the reserve isn’t met, sellers and highest bidders can still negotiate a deal.',
          ]}
          alt="Advanced auctions and offers functionality developed for ifndautoparts marketplace"
          imageTitle="Example of auctions and offers features implemented by Codica team"
          bgRings
          bgRingImage={solutionRing}
          bgRingClass={containerStyles.caseStudySolution__ring}
        />
        <CaseStudySolution
          isImageSmall
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Cross-platform support"
          description="The cross-platform nature of the marketplace was vital to implement. Thanks to this decision, the marketplace is usable and friendly to users browsing from all kinds of platforms. Along with a web version, our team developed separate Android and iOS apps. Notably, both are perfectly adapted to their respective platforms, featuring high loading times and working seamlessly across all devices and platforms."
          alt="An example of advanced iOS and Android apps developed for ifndautoparts marketplace"
          imageTitle="Demonstration of dedicated mobile apps developed by Codica for ifndautoparts marketplace"
        />
      </div>

      <CaseStudyQuote
        quote={[
          {
            text: '“Working on ifndautoparts proved a challenge I am glad to have overcome. Throughout our cooperation, we implemented a plethora of features, each of which was a unique and curious task. Although some were straightforward, others required us to think outside the box to make the feature top-notch.',
          },
          {
            text: 'Overall, working with so many technologies only expanded and strengthened our team’s ability to build robust modern marketplaces. It is surely thrilling to see how marketplaces grow, adapt, and require novelty each year, which our team is definitely ready for.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/Max.png"
            alt={`${quotes.be.name}, ${quotes.be.position} at Codica`}
            title={`${quotes.be.name}, ${quotes.be.position}`}
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.be.name}
        position={quotes.be.position}
      />

      <div className={containerStyles.caseStudyTechnology__bg}>
        <CaseStudyTech
          technologies={[
            { label: 'Ruby', icon: 'ruby' },
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'React Native', icon: 'react' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'Expo', icon: 'expo' },
            { label: 'Redis', icon: 'redis' },
            { label: 'Sidekiq', icon: 'sidekiq' },
            { label: 'Next.js', icon: 'nextjs' },
            { label: 'Puma', icon: 'puma' },
            { label: 'RSpec', icon: 'rspec' },
            { label: 'Swagger', icon: 'swagger' },
            { label: 'AWS', icon: 'aws' },
          ]}
          integrations={[
            { label: 'Elasticsearch', icon: 'elasticsearch' },
            { label: 'Stripe', icon: 'stripe' },
            { label: 'PayPal', icon: 'paypal' },
            { label: 'Easyship', icon: 'easyship' },
            { label: 'Car2DB', icon: 'car2db' },
            { label: 'Sendgrid', icon: 'sendgrid' },
            { label: 'Loqate', icon: 'loqate' },
            { label: 'Twilio', icon: 'twilio' },
            { label: 'Apple OAuth', icon: 'appleOauth' },
            { label: 'Facebook OAuth', icon: 'facebookLogin' },
            { label: 'Google cloud console', icon: 'googleCloud' },
          ]}
        />
      </div>

      <CaseStudyQuote
        isBottom
        quote={[
          {
            text: '“Truthfully, ifndautoparts clearly showed the importance of competent project management. This marketplace, although it has a simple design, is riddled with minute details, all to improve customer experience.',
          },
          {
            text: 'Thus, it required thorough management, and we successfully delivered. After all, our flexible Agile approach made it possible to deliver the product in full, within agreed deadlines and budget.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/PM-Arpi.png"
            alt={`${quotes.pm.name}, ${quotes.pm.position} at Codica`}
            title={`${quotes.pm.name}, ${quotes.pm.position}`}
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.pm.name}
        position={quotes.pm.position}
      />

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewTraderVsTitle}
              color="tradervsColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/tradervs.png"
                  alt={`Case study: ${previewTraderVsTitle} | Codica`}
                  title={`Case study: ${previewTraderVsTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="online-marketplace-for-caravans"
            />
            <CaseStudyPreview
              title={previewDetroitTitle}
              color="ammoColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/detroit-ammo.png"
                  alt={`Case study: ${previewDetroitTitle} | Codica`}
                  title={`Case study: ${previewDetroitTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="marketplace-platform-for-online-firearm-sales"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesIFnd
